import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ProductSummaryCard } from 'components/ProductSummaryCard/ProductSummaryCard';
import { TopPageCustomSections } from 'components/TopPageCustomSections/TopPageCustomSections';
import { BookingWidgetSectionHeader } from 'components/Themes/Default/BookingWidgetSectionHeader/BookingWidgetSectionHeader';
import { ProductListViewProps } from 'lib/themes/themes';

import styles from './ProductListView.module.css';
import { KeyVisualView } from './KeyVisualView';

const ProductListView = ({
  bannerImageUrls,
  bannerTitle,
  bannerDescription,
  productSummaries,
}: ProductListViewProps) => {
  const { t } = useTranslation();

  return (
    <>
      {bannerImageUrls.length > 0 && (
        <div className="base-top">
          <KeyVisualView imageUrls={bannerImageUrls} />
          <div
            className={clsx(
              'base-top__body',
              bannerImageUrls.length > 1 && styles['mobile-indent']
            )}
            style={bannerImageUrls.length > 1 ? { paddingLeft: '44px' } : undefined}
          >
            {bannerTitle && <h1 className="base-top__body__headline">{bannerTitle}</h1>}
            {bannerDescription && (
              <p className="base-top__body__description">{bannerDescription}</p>
            )}
          </div>
        </div>
      )}
      <TopPageCustomSections />
      <BookingWidgetSectionHeader text={t('Products')} />
      <article className="base-archive">
        <ul className="base-archive__list">
          {productSummaries.map((productSummary, idx) => (
            <li key={idx} className="base-archive__list__item col-3">
              <ProductSummaryCard product={productSummary} />
            </li>
          ))}
        </ul>
      </article>
    </>
  );
};

export default ProductListView;
