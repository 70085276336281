import * as React from 'react';

import { CSSProperties } from '@material-ui/styles';
import styles from './KeyVisualView.module.css';

interface Props {
  imageUrls: string[];
}

export const KeyVisualView = ({ imageUrls }: Props) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  if (imageUrls.length === 0) {
    return null;
  }

  const mainImageStyle: CSSProperties = {
    transform: `translateX(-${activeIndex * 100}%)`,
  };

  const handlePrev = () =>
    setActiveIndex(activeIndex <= 0 ? imageUrls.length - 1 : activeIndex - 1);

  const handleNext = () =>
    setActiveIndex(activeIndex >= imageUrls.length - 1 ? 0 : activeIndex + 1);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      handleNext();
    }, 5 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [activeIndex]);

  return (
    <div className={styles['base-top__pic']}>
      <div className={styles['c-photo__main']}>
        <ul className={styles['c-photo__main__list']}>
          {imageUrls.map((url) => (
            <li className={styles['c-photo__main__list__item']} style={mainImageStyle} key={url}>
              <img src={url} alt="banner" />
            </li>
          ))}
        </ul>
      </div>
      {imageUrls.length > 1 && (
        <>
          <button className={styles['c-photo__main__prev']} onClick={handlePrev} />
          <button className={styles['c-photo__main__next']} onClick={handleNext} />
        </>
      )}
    </div>
  );
};
